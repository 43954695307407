import React, { useState, useEffect } from 'react';

import css from './Hero.module.css';
import HeroImg from '../../assets/hero.png';
import { RiShoppingBagFill } from 'react-icons/ri';
import { BsArrowRight } from 'react-icons/bs';
import { motion } from 'framer-motion';

import { useIsMobile } from '../../hooks/useIsMobile';

export function Hero() {
  const isMobile = useIsMobile();
  const cartPositionByScreen = {
    mobile: { right: '-41%', bottom: '-14%' },
    tablet: {
      bottom: ' 25%',
      right: '5%',
    },
  };
  const [initialCartPosition, setInitialCartPosition] = useState(
    isMobile ? cartPositionByScreen['mobile'] : cartPositionByScreen['tablet']
  );


  const transition = { duration: 3, type: 'spring' };
  return (
    <div className={css.container}>
      {/* left side */}
      <div className={css.h_sides}>
        <span className={css.text1}>skin protection cream</span>
        <div className={css.text2}>
          <span>Trendy Collection</span>
          <span>
            Seedily say has suitable disposal and boy.Seedily say has suitable
            disposal and boy
          </span>
        </div>
      </div>
      {/* middle side hero image */}
      <div className={css.wrapper}>
        <motion.div
          initial={{ bottom: '2rem' }}
          whileInView={{ bottom: '0rem' }}
          className={css.blueCircle}
          transition={transition}
        ></motion.div>
        <motion.img
          initial={{ bottom: '2rem' }}
          whileInView={{ bottom: '0rem' }}
          transition={transition}
          src={HeroImg}
          alt=""
          width={600}
        />
        {!isMobile && (
          <motion.div
            initial={{ ...initialCartPosition['tablet'] }}
            whileInView={{ ...initialCartPosition['tablet'] }}
            transition={transition}
            className={css.cart2}
          >
            <RiShoppingBagFill />
            <div className={css.signup}>
              <span>Best Sign up Offers</span>
              <div>
                <BsArrowRight />
              </div>
            </div>
          </motion.div>
        )}
        {isMobile && (
          <motion.div
            initial={{ ...initialCartPosition['mobile'] }}
            whileInView={{ ...initialCartPosition['mobile'] }}
            transition={transition}
            className={css.cart2}
          >
            <RiShoppingBagFill />
            <div className={css.signup}>
              <span>Best Sign up Offers</span>
              <div>
                <BsArrowRight />
              </div>
            </div>
          </motion.div>
        )}
      </div>
      <div className={css.h_sides}>
        <div className={css.traffic}>
          <span className="">1.5m</span>
          <span>Monthly Traffic</span>
        </div>
        <div className={css.customers}>
          <span>100k</span>
          <span>Happy Customers</span>
        </div>
      </div>
    </div>
  );
}
