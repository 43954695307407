import './App.css';
import {
  Hero,
  MainHeader,
  Slider,
  Virtual,
  Products,
  Footer,
} from './components';
function App() {
  return (
    <div className="App">
      <MainHeader />
      <Hero />
      <Slider />
      <Virtual />
      <Products />
      <Footer />
    </div>
  );
}

export default App;
