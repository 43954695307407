import React, { useCallback, useEffect, useState } from 'react';
import css from './MainHeader.module.css';
import Logo from '../../assets/logo.png';
import { CgShoppingBag } from 'react-icons/cg';
import { GoThreeBars } from 'react-icons/go';
import { useIsMobile } from '../../hooks/useIsMobile';

export const MainHeader = () => {
  const isMobile = useIsMobile();
  console.log(isMobile);
  const [showMenu, setShowMenu] = useState(isMobile);
  const toggleMenu = useCallback(() => {
    setShowMenu((showMenu) => !showMenu);
  }, []);

  useEffect(() => {
    console.log(isMobile, window.innerWidth);
    if (isMobile) setShowMenu(false);
    else setShowMenu(true);
  }, [isMobile]);
  return (
    <div className={css.container}>
      <div className={css.logo}>
        <img src={Logo} alt="" />
        <span>Amazon</span>
      </div>
      <div className={css.right}>
        {/* mobile */}
        <div className={css.bars} onClick={toggleMenu}>
          <GoThreeBars />
        </div>
        {/* end */}

        <ul
          className={css.menu}
          style={{ display: showMenu ? 'inherit' : 'none' }}
        >
          <li> Collections</li>
          <li> Brands </li>
          <li> New </li>
          <li> Sales </li>
          <li> EN </li>
        </ul>
        <input placeholder="search" type="text" className={css.search} />
        <CgShoppingBag className={css.cart} />
      </div>
    </div>
  );
};

export default MainHeader;
